/*validacion*/
.error{
  box-shadow: 0 0 5px 1px red !important;
}

/*payment*/
.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}
.Checkout h1{
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 40px;
  margin: 20px 0;
  padding: 0;
}
.Checkout label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  display: block;
}
.Checkout button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}
.Checkout form {
  margin-bottom: 40px;
  padding-bottom: 40px;
}
.Checkout button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}
.Checkout input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

/*correccion*/
.is-number::-webkit-inner-spin-button, 
.is-number::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.is-number{ 
  -moz-appearance:textfield; 
  padding: 5px !important;
}

.rs-drawer-backdrop.in{
  opacity:0.3 !important;
}
.rs-modal-backdrop.fade{
  opacity: 0.3 !important;
}
.fade{
  opacity: 1 !important;
}

.collapse {
  display: none !important;
}
.collapse.in{
  display: block !important;
}

.rs-navbar-nav .rs-btn-toolbar .rs-dropdown > .rs-dropdown-toggle{
  padding: 12px 16px !important;
  padding-right: 36px !important;
  height: 42px !important;
}

.rs-navbar-nav .rs-btn-toolbar  .rs-dropdown-toggle-caret{
  top: 13px !important;
}

.rs-picker-menu{
  width: 30% !important;
}
.rs-picker-menu > ul{
  
  height: 150px !important;
  overflow: scroll !important;
}

.rs-picker-toggle{
  padding-left: 2px !important;
}

div.dt-buttons{
  float: right !important;
}

div.dataTables_wrapper div.dataTables_filter{
  text-align: left !important;
}

table.table-fixed{
  table-layout: fixed;
}
table.table-auto{
  table-layout: auto;
}

.report-name > .rs-panel-heading{
  padding: 5px;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  height: 50% !important;
}

.karya-table-row {
  background-color:#ff000073;
}

.rs-table-cell {
  background:transparent !important
}